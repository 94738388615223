import Rollbar from 'rollbar';

import p from '../package.json';
/**
 * TODO
 * 'process' is undefined on prod. need separate script to generate
 * this file with values before build step on production build pipeline
 */
const NODE_ENV: string = process?.env?.ENV || 'production';
const LOG_LEVEL: string = process?.env?.LOG_LEVEL || 'LOG';
const ROLLBAR_ACCESS_TOKEN: string = process?.env?.ROLLBAR_ACCESS_TOKEN || 'c1b63d468dda41b59e9bc85cb42b7d5f';
const TAG_NAME: string = process?.env?.RELEASE_VERSION || p.version;
const rollbar = new Rollbar({
  accessToken: ROLLBAR_ACCESS_TOKEN,
  environment: NODE_ENV,
  enabled: NODE_ENV !== 'development',
  captureUncaught: true,
  captureUnhandledRejections: true,
  sendConfig: false,
  hostBlockList: ['localhost', '127.0.0.1'],
  includeItemsInTelemetry: true,
  maxTelemetryEvents: 3,
  payload: { version: TAG_NAME, environment: NODE_ENV },
  ignoredMessages: [
    '^jwt expired',
    '^NotAuthenticated: jwt expired',
    '^Socket server did not execute the callback for setAttributes with data',
    '^Uncaught Error: Socket server did not execute the callback for setAttributes with data',
  ],
});

rollbar.global({ itemsPerMinute: 10 });
rollbar.configure({
  payload: { version: TAG_NAME, environment: NODE_ENV },
});

const logger = NODE_ENV === 'development' ? console : rollbar;

export default {
  info(message, payload: any = {}) {
    if (
      ['staging', 'production'].includes(NODE_ENV) &&
      (!['LOG', 'INFO'].includes(LOG_LEVEL) || ['NONE'].includes(LOG_LEVEL))
    ) {
      return;
    }
    rollbar.configure({ payload });
    logger.log(message);
  },
  warn(message, payload: any = {}) {
    if (
      ['staging', 'production'].includes(NODE_ENV) &&
      (!['LOG', 'INFO', 'WARN'].includes(LOG_LEVEL) || ['NONE'].includes(LOG_LEVEL))
    ) {
      return;
    }
    rollbar.configure({ payload });
    logger.warn(message);
  },
  error(message, payload: any = {}) {
    if (['staging', 'production'].includes(NODE_ENV) && ['NONE'].includes(LOG_LEVEL)) {
      return;
    }
    rollbar.configure({ payload });
    logger.error(message);
  },
  rollbar,
};
