import { blue, grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

/**
 * customizing default theme styles of
 * materialUI
 */
export default createTheme({
  typography: {
    fontFamily: ['Roboto', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Arial', 'sans-serif'].join(','),
    // useNextVariants: true,
  },
  palette: {
    primary: blue,
    secondary: red,
    default: {
      dark: grey[400],
      main: grey[300],
    },
    background: {
      default: grey[50],
    },
  } as any,
  components: {
    // DataGridPro
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          minHeight: '35px !important',
          maxHeight: '35px !important',
        },
        columnHeader: {
          minHeight: '35px !important',
          maxHeight: '35px !important',
        },
        columnHeaderTitle: {
          minHeight: '35px !important',
          maxHeight: '35px !important',
        },
        footerContainer: {
          overflow: 'hidden !important',
          minHeight: '35px !important',
          maxHeight: '35px !important',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          overflow: 'hidden !important',
          minHeight: '35px !important',
          maxHeight: '35px !important',
        },
        toolbar: {
          overflow: 'hidden !important',
          minHeight: '35px !important',
          maxHeight: '35px !important',
        },
      },
    },
    // end DataGridPro

    MuiButtonBase: {
      defaultProps: { disableRipple: true },
      styleOverrides: { root: { minWidth: '30.75px !important' } },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          minWidth: 31,
          height: 'fit-content',
          minHeight: 31,
        },
      },
    },
    MuiPaper: { styleOverrides: { root: { overflow: 'visible !important' } } },
    MuiDialog: {
      styleOverrides: {
        paper: {
          // width: '100%',
          // maxWidth: '600px !important',
          maxWidth: 'calc(100vw - 16px) !important',
          display: 'unset !important',
          position: 'unset',
          overflowX: 'scroll',
          margin: '2px !important',
          padding: '8px !important',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: '8px 0 0 0 !important',
          padding: 0,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        entered: {
          height: 'auto',
          overflow: 'visible !important',
        },
      },
    },
    // @ts-ignore
    MuiPickersModal: {
      styleOverrides: {
        dialogRoot: {
          width: 'unset !important',
          minWidth: 'unset !important',
          maxWidth: 'unset !important',
        },
        dialog: { padding: '0 !important' },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          minHeight: 30.75,
          marginLeft: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 3,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&$outlined': {
            transform: 'translate(14px, 12px) scale(1)',
          },
          '&[data-shrink="false"]': {
            top: '-12px !important',
            left: -6,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // height: 30.75,
          padding: '0px !important',
          paddingLeft: '4px !important',
          '&$disabled': { color: '#333 !important' },
        },
        input: {
          // height: 22.75,
          padding: '4px !important',
          minHeight: '22.75px !important',
        },
        multiline: {
          padding: '0px !important',
          paddingLeft: '4px !important',
          '&$disabled': { color: '#333 !important' },
          minHeight: '22.75px !important',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        avatar: {
          marginLeft: 0,
          marginRight: -4,
        },
        deleteIcon: {
          height: 24,
          width: 24,
          margin: 0,
          padding: 0,
          marginLeft: -4,
        },
        label: {
          paddingLeft: 8,
          paddingRight: 8,
        },
        root: {
          margin: 1,
          height: '24px !important',
          padding: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 3,
          paddingRight: 3,
          paddingLeft: 4,
        },
      },
    },
    MuiFormHelperText: { styleOverrides: { root: { marginTop: '4px !important' } } },
    MuiAccordion: {
      styleOverrides: {
        root: {
          minHeight: '30.75px !important',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: 8,
          paddingRight: 8,
          borderRadius: 4,
          minHeight: '30.75px !important',
        },
        content: { margin: '0 !important' },
      },
    },
    MuiAccordionDetails: { styleOverrides: { root: { padding: '8px 8px 0 8px !important' } } },
    MuiListItem: {
      styleOverrides: {
        root: {
          minHeight: 'unset !important',
          margin: 0,
          // height: 30.75,
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiListItemText: { styleOverrides: { root: { paddingLeft: '0 !important;' } } },
    MuiSelect: {
      styleOverrides: {
        select: {
          // marginRight: '24px !important',
          width: 'calc(100% - 16px) !important',
        },
      },
    },
    MuiOutlinedInput: { styleOverrides: { adornedEnd: { paddingRight: 0 } } },
    MuiTabs: {
      styleOverrides: {
        // root: { overflow: 'visible !important' },
        // fixed: { overflow: 'visible !important' },
        flexContainer: { height: 30.75 },
      },
    },
  },
});
