// components/TerminalWrapper.tsx

import React, { createContext, useCallback, useContext, useState } from 'react';

import useBarcodeScanner from '../hooks/useBarcodeScanner';

interface TerminalContextType {
  lastScannedCode: string;
  isScanning: boolean;
  globalState: {
    [key: string]: any;
  };
  setGlobalState: (key: string, value: any) => void;
}

const TerminalContext = createContext<TerminalContextType | undefined>(undefined);

export const useTerminal = () => {
  const context = useContext(TerminalContext);
  if (!context) {
    throw new Error('useTerminal must be used within a TerminalWrapper');
  }
  return context;
};

interface TerminalWrapperProps {
  children: React.ReactNode;
}

export const TerminalWrapper: React.FC<TerminalWrapperProps> = ({ children }) => {
  const [lastScannedCode, setLastScannedCode] = useState('');
  const [globalState, setGlobalStateInternal] = useState<{ [key: string]: any }>({});

  const handleScan = useCallback((scannedCode: string) => {
    setLastScannedCode(scannedCode);
    // You can add additional logic here, like updating specific global state based on the scan
  }, []);

  const { isScanning } = useBarcodeScanner({ onScan: handleScan });

  const setGlobalState = useCallback((key: string, value: any) => {
    setGlobalStateInternal(prev => ({ ...prev, [key]: value }));
  }, []);

  const contextValue: TerminalContextType = {
    lastScannedCode,
    isScanning,
    globalState,
    setGlobalState,
  };

  return <TerminalContext.Provider value={contextValue}>{children}</TerminalContext.Provider>;
};
