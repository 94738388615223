import { useCallback, useEffect, useRef, useState } from 'react';

interface BarcodeScannerOptions {
  onScan: (scannedCode: string) => void;
  scanTimeout?: number;
}

const useBarcodeScanner = ({ onScan, scanTimeout = 50 }: BarcodeScannerOptions) => {
  const [isScanning, setIsScanning] = useState(false);
  const scanBufferRef = useRef('');
  const scanTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleScan = useCallback(
    (scannedCode: string) => {
      onScan(scannedCode);
      setIsScanning(false);
      scanBufferRef.current = '';
    },
    [onScan]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Ignore if it's a 'real' keypress (e.g., user typing in an input field)
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        return;
      }

      // Start or continue scanning
      setIsScanning(true);

      if (event.key === 'Enter') {
        // Scan complete
        if (scanBufferRef.current) {
          handleScan(scanBufferRef.current);
        }
        if (scanTimeoutRef.current) {
          clearTimeout(scanTimeoutRef.current);
        }
      } else if (event.key.length === 1) {
        // Only add printable characters to the buffer
        scanBufferRef.current += event.key;

        // Clear any existing timeout
        if (scanTimeoutRef.current) {
          clearTimeout(scanTimeoutRef.current);
        }

        // Set a new timeout (fallback in case 'Enter' is not received)
        scanTimeoutRef.current = setTimeout(() => {
          if (scanBufferRef.current) {
            handleScan(scanBufferRef.current);
          }
        }, scanTimeout);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (scanTimeoutRef.current) {
        clearTimeout(scanTimeoutRef.current);
      }
    };
  }, [handleScan, scanTimeout]);

  return { isScanning };
};

export default useBarcodeScanner;
